import React from 'react';
import styled from 'styled-components';
import Faq from '../components/faq/Faq';
import bird2 from '../assets/images/birds/bird3.png';
import bird3 from '../assets/images/birds/bird1.png';
import eye from '../assets/images/icons/eye.svg';
import compass from '../assets/images/icons/compass.svg';
import food from '../assets/images/icons/food.svg';
import mouth from '../assets/images/icons/mouth.svg';
import sound from '../assets/images/icons/sound.svg';
import Experience from '../components/experiences/experience';
// experiences
import experiences1 from '../assets/images/experiences/chefhands_web.jpg';
import experiences2 from '../assets/images/experiences/myanalogjournal_web.jpg';
import experiences3 from '../assets/images/experiences/pool.gif';
import experiences4 from '../assets/images/experiences/wellness.jpg';
import experiences5 from '../assets/images/experiences/bagichi.jpg';
import experiences6 from '../assets/images/experiences/tigersafari.jpg';
import raybanden from '../assets/images/experiences/raybanden.jpg';
import pco from '../assets/images/experiences/pco.jpg';
import experiences7 from '../assets/images/experiences/elixirsthumb.jpg';
import experiences8 from '../assets/images/experiences/food-fair.jpg';
import experiences9 from '../assets/images/experiences/pizza.jpg';
import experiences10 from '../assets/images/experiences/peacock.jpg';
import experiences11 from '../assets/images/experiences/bartender.jpg';
import souk from '../assets/images/experiences/souk.jpg';
// import motifs
// motifs
import Mountain1 from '../components/motifs/motif3';
import Tiger1 from '../components/motifs/motif4';
import Tree1 from '../components/motifs/motif5';
import Fort1 from '../components/motifs/motif6';
import Tree2 from '../components/motifs/motif7';
import Tree3 from '../components/motifs/motif8';
import River2 from '../components/motifs/motif9';
import Tree4 from '../components/motifs/motif10';
import Mountain2 from '../components/motifs/motif11';
import Hill1 from '../components/motifs/motif1';

// covid
import Covid from '../components/covid/covid';

// travelines
import Travelline from '../components/travellines/Travelline1';

// motifs

import nomadsmotif from '../assets/images/motifs/experiences.png';

const Page = styled.div`
  margin-top: 7.5vw;

  .siteWrapper {
    display: grid;
    grid-template-columns: repeat(18, 1fr);
    grid-column: 1 / span 24;
    @media (min-width: 768px) {
      grid-column: 5 / span 16;
    }
    .experiences-grid {
      display: grid;
      grid-column: 1 / span 24;
      grid-template-columns: repeat(1, 1fr);
      @media (min-width: 960px) {
        grid-template-columns: repeat(2, 1fr);
      }
      grid-template-rows: masonry;
      grid-gap: 30px;
    }
  }
  header {
    grid-column: 1 / span 18;

    @media (min-width: 960px) {
      grid-column: 1 / span 9;
    }
    padding: 3.5vw 0 2vw 0;
    .intro-text {
      max-width: 440px;
      .title {
        font-size: 2.2rem;

        @media (min-width: 768px) {
          font-size: 3rem;
        }
      }
      .sub-title {
        text-transform: uppercase;
        font-size: 1.2rem;
        margin-top: 5px;
      }
      h1 {
        font-size: 3em;
        padding-bottom: 30px;
      }
    }
  }
  .header-right {
    grid-column: 10 / span 18;
    position: relative;
  }
  min-height: 100vh;
`;

const NomadsMotif = styled.div`
  position: absolute;
  top: 20%;
  left: 25%;
  opacity: 0.3;
  max-width: 200px;
`;

const Bird2 = styled.div`
  position: absolute;
  left: -70px;
  top: 0%;
  z-index: 0;
  width: 40vw;
  max-width: 400px;
  height: auto;
  opacity: 0.7;
`;

const Bird3 = styled.div`
  position: absolute;
  right: -70px;
  top: 400px;
  z-index: 0;
  width: 40vw;
  max-width: 350px;
  height: auto;
  opacity: 0.7;
`;
const ExperiencesStyles = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: masonry;
  grid-gap: 0;
`;
export default function Experiences() {
  return (
    <>
      <Page>
        <Travelline />
        <Tiger1 />
        <Fort1 />
        <Tiger1 />
        <Tree1 />
        <Hill1 />
        <Mountain2 />
        <Tree3 />
        <Bird2>
          <img src={bird2} alt="" />
        </Bird2>
        <Bird3>
          <img src={bird3} alt="" />
        </Bird3>
        <div className="SiteContainer">
          <div className="siteWrapper">
            <header>
              <div className="intro-text">
                <h1 className="title">
                  DISCOVER NOMADS THROUGH INTIMATE CURATED EXPERIENCES.
                </h1>
                <div className="text">
                  <p className="sub-title b">The best of the fields.</p>
                  <p>
                    Nomads are connected through a shared desire to be
                    delighted, fascinated and intrigued; to share new
                    experiences and to connect with nature. An intimate getaway
                    fuelled by discovery where you can explore, wander and get
                    lost in one of the world’s most unique and surreal venues.
                  </p>
                </div>
              </div>
            </header>
            <div className="header-right">
              <NomadsMotif>
                <img src={nomadsmotif} alt="" />
              </NomadsMotif>
            </div>

            <div className="experiences-grid">
              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="The Chef's Table"
                  text="
                Come get up-close-and-personal with handpicked chefs from
                across India at an intimate, seated dining experience. 
              "
                  icon={food}
                  image={experiences1}
                  linkto="/experiences/chefs-table"
                  linktext="More Information"
                />
              </ExperiencesStyles>
              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="Magnetic Sanctuary by Vitality Hours"
                  text="Magnetic Sanctuary is a tranquil retreat within the festival where you can revive and revitalise your body and mind through a variety of wellness workshops."
                  icon={eye}
                  image={experiences4}
                  linkto="/experiences/magnetic-sanctuary"
                  linktext="More Information"
                />
              </ExperiencesStyles>
              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="The Easy Picnic"
                  text="    Find a cosy spot, a refreshing cocktail, some people you love
                  and let the warm rays of the day soak into your soul
                "
                  icon={sound}
                  image={experiences2}
                  linkto="/experiences/easy-picnic"
                  linktext="More Information"
                />
              </ExperiencesStyles>
              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="The Souk"
                  text="A specially curated festival marketplace where you can revel in and discover the best in vintage, upcycled and independently designed apparel and products."
                  icon={eye}
                  image={souk}
                  linkto="/experiences/the-souk"
                  linktext="More Information"
                />
              </ExperiencesStyles>
              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="Ray-Ban Studios Pool Party"
                  text="Carved gazebos, inviting sun loungers, handcrafted summer cocktails and joy-inducing tropical sounds is what awaits you at the Ray-Ban Studios Pool Party. 
                "
                  icon={sound}
                  image={experiences3}
                  linkto="/experiences/a-grand-pool-party"
                  linktext="More Information"
                />
              </ExperiencesStyles>
              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="The Peacock Club"
                  text="The majestic traveling theatre arrives at Magnetic Fields
                Nomads, offering a rich program of cabaret inspired
                performances."
                  icon={sound}
                  image={experiences10}
                  linkto="/experiences/the-peacock-club"
                  linktext="More Information"
                />
              </ExperiencesStyles>
              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="Jameson Sundowner"
                  text="Dance around the warm rays of the afternoon sun as they peek through the canopy of leaves. As the sun sets, we will take you on a magical journey into the night with some of India’s best DJs.
                  "
                  icon={sound}
                  image={experiences5}
                  linkto="/experiences/sundowner"
                  linktext="More Information"
                />
              </ExperiencesStyles>
              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="Ray-Ban Studios Den"
                  text="Where the outside comes inside to play. Inspired by the forest and the predators that call it home, the Den will host some of the finest sounds and styles from the underground.  


                "
                  icon={compass}
                  image={raybanden}
                  linkto="/experiences/ray-ban-studios-den"
                  linktext="More Information"
                />
              </ExperiencesStyles>
              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="The Prohibition Party by PCO 
                  "
                  text="One of Delhi’s most popular speakeasy bars PCO comes to Nomads for a very special pop-up."
                  icon={mouth}
                  image={pco}
                  linkto="/experiences/pco"
                  linktext="More Information"
                />
              </ExperiencesStyles>
              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="A Jungle Safari"
                  text="No visit to Ranthambore is complete without a jungle safari.
                "
                  icon={compass}
                  image={experiences6}
                  linkto="/ranthambore"
                  linktext="More Information"
                />
              </ExperiencesStyles>

              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="The Elixir Bar by Bhu Kombucha"
                  text="Elixirs to heal, rejuvenate and energise from the nomadic wisdom of our ancestors."
                  icon={mouth}
                  image={experiences7}
                  linkto="/experiences/ancient-elixirs"
                  linktext="More Information"
                />
              </ExperiencesStyles>
              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="The Food Garden"
                  text="Forget any preconceptions you have about festival food. At Nomads the food and drink offerings are as exciting and diverse as our cultural content. 
                "
                  icon={food}
                  image={experiences9}
                  linkto="/experiences/food-garden"
                  linktext="More Information"
                />
              </ExperiencesStyles>
              <ExperiencesStyles className="experiences">
                <Experience
                  className="experience"
                  title="Bartender Experiences"
                  text="A weekend bursting with designer cocktails from the country's leading bartenders awaits you.
                "
                  icon={mouth}
                  image={experiences11}
                  linkto="/experiences/bartender-experiences"
                  linktext="More Information"
                />
              </ExperiencesStyles>
            </div>
          </div>
        </div>
      </Page>
    </>
  );
}
