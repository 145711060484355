import React from 'react';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import Button from '../button';

// transitionimage
import transitionimage from '../../assets/images/transition.jpg';

const ExperienceStyles = styled.div`
  grid-column: 1 / span 4;
  margin-bottom: 20px;
`;

const SlideCard = styled.div`
  padding: 0;
  position: relative;

  max-width: auto;
  img {
    width: 100%;
  }
  .text {
    font-size: 16px;
    padding-right: 20px;
    position: relative;
    .title {
      text-transform: uppercase;
      font-size: 1.2rem;
      margin-top: 15px;
      margin-bottom: 5px;
    }
    .copy {
      margin-top: 15px;
      max-width: 100%;
      //line-height: 1.;
    }
    .footer {
      img {
        width: 30px;
      }
    }
    .footer {
      position: absolute;
      right: 0;
      top: -5px;

      img {
        width: 30px;
      }
    }
  }
`;

const transitionImageDiv = `url('${transitionimage}') center / cover  no-repeat fixed padding-box content-box white`;

export default function Experience({
  image,
  icon,
  title,
  text,
  linkto,
  linktext,
}) {
  const Proptext = text;
  const newText = Proptext.split('<br>').map((str) => <p key={str}>{str}</p>);

  return (
    <ExperienceStyles className="experience">
      <SlideCard>
        <AniLink
          to={linkto}
          cover
          direction="down"
          activeClassName="active"
          bg={transitionImageDiv}
        >
          <img src={image} alt="" />
        </AniLink>
        <div className="text">
          <div className="footer">
            <img src={icon} alt="" />
          </div>
          <div className="title sb-i">{title}</div>
          <div className="copy">{newText}</div>
        </div>
      </SlideCard>
      {linkto ? <Button linktext={linktext} linkto={linkto} /> : null}
    </ExperienceStyles>
  );
}
